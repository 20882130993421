import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, HeroIcon } from '@clarke-energia/foton';
import { BidWithSavings } from '@hooks/bids/savings/types';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import {
  PREVIEW_CUSTOMER_COMPILATION_SUFFIX_PATH,
  VIEW_PROPOSAL_LIST_PATH,
  VIEW_SAVINGS_RESULTS_PATH,
} from '@routers/constants';

import SavingsScope from './savings-scope';
import { useSavingsContext } from './context';

interface CompilationHasResultsDisplayProps {
  proposal: Proposal;
  bids: BidWithSavings[];
}
const CompilationHasResultsDisplay: React.FC<CompilationHasResultsDisplayProps> = ({ bids, proposal }) => {
  const { tokenForProposal } = useSavingsContext();

  return (
    <>
      <div data-cy="compilation-with-results-panel" className="flex flex-col space-y-8">
        <div className="flex flex-col space-y-3 max-w-xl">
          <h1 className="text-heading-2xlarge">Envie o compilado para o cliente</h1>
          <p className="text-paragraph-medium">
            Ao gerar o link no botão abaixo, você terá a versão resumida e detalhada das propostas, além de poder ter a
            visão do grupo comercial e separado por unidade consumidora. Lembre-se de fazer comentários sobre cada
            proposta ao enviar para o cliente por email.
          </p>
        </div>

        <SavingsScope {...{ bids, scopeType: 'inside' }} />

        <SavingsScope {...{ bids, scopeType: 'outside' }} />

        <div className="flex mr-auto space-x-4">
          <Button
            type="button"
            kind="primary"
            icon="DocumentTextIcon"
            label="Copiar link do compilado"
            onClick={async () => {
              const url = `${window.location.origin}${VIEW_SAVINGS_RESULTS_PATH}/${proposal.id}?token=${tokenForProposal}`;

              try {
                await navigator.clipboard.writeText(url);
                alert('URL copiada com sucesso! Basta colar onde quiser!');
              } catch (error) {
                alert('Desculpa, mas não foi possível copiar a URL. :(');
              }
            }}
          />

          <NavLink
            to={`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${PREVIEW_CUSTOMER_COMPILATION_SUFFIX_PATH}?token=${tokenForProposal}`}
            className="flex py-1.5 px-6 mr-auto space-x-2 font-medium bg-white rounded-full border border-black text-paragraph-large hover:bg-neutral-20"
          >
            <HeroIcon icon="CheckCircleIcon" extraClassNames="w-6 h-6" />
            <span>Visualizar prévia do compilado</span>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default CompilationHasResultsDisplay;
